import moment from 'moment'
import "magnific-popup"

jQuery(document).ready(function ($) {

  // NAVIGATION
  $('section').hide();
  $('#home').show();

  $('.nav-item').on('click', function (event) {
    event.preventDefault();
    $('section').hide();
    var target = $(this).attr('href');
    $(target).show();
  });


  // Adding classes on clicks
  $('.album-promo').on('click', function () {
    $('.album-promo').addClass('active');
  });

  $('#messenger').on('click', function () {
    $('#messenger').addClass('active');
  });

  $('#buddy-list').on('click', function () {
    $('#buddy-list').addClass('active');
  });

  // Removing classes when main window is clicked
  $('.main-window').on('click', function () {
    $('.album-promo').removeClass('active');
    $('#messenger').removeClass('active');
    $('#buddy-list').removeClass('active');
  });


  // CLOCK
  var pastDate = moment().subtract(20, 'years');
  var formattedDate = pastDate.format('dddd, MMMM D, YYYY');
  $('#date-time-display').text(formattedDate);


  // BIT IMPORT
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_491/events?app_id=45PRESS_GAVIN_DEGRAW',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      console.log(data)
      const events = $('#tour-dates');
      let html = '';
      let n = 0
      if (data.length) {
        for (let event of data) {
          n++
          html += '<a href="' + event.url + '" target="_blank" class="link">';
          html += '<div class="event-group">';
          html += '<div class="event-date">' + moment(event.datetime).format('M/DD').toUpperCase() + '</div>';
          html += '<div class="event-time">' + moment(event.datetime).format('h:mma').toUpperCase() + '</div>';
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
          html += '</div>';
          html += '</a>';
        }
        events.html(html);
      } else {
        events.html('<span class="no-events">Check back soon for new shows!</span>');
      }
      if (n < 9) {
        $("#toggle-dates").hide();
      }
    }
  });

  // LIGHTBOX
  $('.img-wrapper').magnificPopup({ type: 'image' })


  // ANIMATION
  $('.loaded').addClass('percent-3');

  setTimeout(function () {
    $('.first').fadeIn();
    $('.loaded').removeClass('percent-3').addClass('percent-25');

    setTimeout(function () {
      $('.second').fadeIn();
      $('.loaded').removeClass('percent-25').addClass('percent-50');

      setTimeout(function () {
        $('.third').fadeIn();
        $('.activate-timewarp').addClass('active');
        $('.loaded').removeClass('percent-50').addClass('percent-100');

        setTimeout(function () {
          $('#time-warp').hide();
          $('#messenger').show();
          $('#buddy-list').show();

          setTimeout(function () {
            $('.album-promo').fadeIn();

            setTimeout(function () {
              $('.album-promo').fadeIn();

              setTimeout(function () {
                $('#main-container').fadeIn();

              }, 300);
            }, 300);
          }, 500);
        }, 1000);
      }, 500);
    }, 1000);
  }, 1000);
});